import React, { useEffect, useState } from 'react';
import tw from 'twin.macro';
import { graphql, StaticQuery } from 'gatsby';
import { css } from '@emotion/react';
import Img, { FluidObject } from 'gatsby-image';
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import { ReactComponent as Ellipse } from '../assets/Ellipse.svg';
import { GetPartnerDataQuery } from '../graphql-types';

interface PartnerCompProps {
  data?: GetPartnerDataQuery;
  title?: any;
  noMargin?: boolean;
  partners?: any;
  dynamic?: boolean;
}

const PartnerComp: React.FC<PartnerCompProps> = ({ data, title, noMargin, partners, dynamic }) => {
  const partnerGroups = sliceIntoChunks(partners, 6);

  const uid = data?.uid?.childrenImageSharp[0]?.fluid;
  const mutiara = data?.mutiara?.childrenImageSharp[0]?.fluid;
  const transgasindo = data?.transgasindo?.childrenImageSharp[0]?.fluid;
  const narasi = data?.narasi?.childrenImageSharp[0]?.fluid;
  const danaDidik = data?.danaDidik?.childrenImageSharp[0]?.fluid;
  const akseleran = data?.akseleran?.childrenImageSharp[0]?.fluid;

  let timeout!: NodeJS.Timeout;
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => autoSlide(), [currentIndex]);

  function autoSlide() {
    timeout = setTimeout(() => {
      if (currentIndex === partnerGroups.length - 1) {
        setCurrentIndex(0);
      } else {
        setCurrentIndex(currentIndex + 1);
      }
    }, 10000);
    return () => clearTimeout(timeout);
  }

  function sliceIntoChunks(arr, chunkSize) {
    const res = [];
    if (!arr) {
      return res;
    }
    for (let i = 0; i < arr.length; i += chunkSize) {
      const chunk = arr.slice(i, i + chunkSize);
      res.push(chunk);
    }
    return res;
  }

  const totalData = partnerGroups?.length || 0;
  const activeIdx = currentIndex;
  const activeItem = partnerGroups[activeIdx];

  function getSizeFromUrl(url, mobile = false) {
    if (!url) {
      return {
        h: '50px',
        w: '50px',
      };
    }
    const urls = url.split(',');
    const raw = new URLSearchParams(urls[0]);
    const query = {
      h: +raw.get('h'),
      q: raw.get('q').toString(),
    };

    let ratio = mobile ? 0.7 : 1;

    let height = query.h;
    let width = +query.q.split(' ')[1].slice(0, -1);

    height *= ratio;
    width *= ratio;

    const maxHeight = mobile ? 40 : 70;

    if (height >= maxHeight) {
      const rat = maxHeight / height;
      height = maxHeight;
      width = rat * width;
    }

    return {
      h: height + 'px',
      w: width + 'px',
    };
  }

  return (
    <section tw="bg-white dark:bg-black-200">
      <div tw="container">
        <div
          tw="py-4"
          css={css`
            ${!noMargin ? tw`mx-8 lg:mx-32` : ''}
          `}
        >
          {title || (
            <h2 tw="text-h4 lg:text-h3 text-black-300 text-center py-6 m-0">
              Sudah Dipercaya oleh 3000+ Perusahaan di Indonesia
            </h2>
          )}
          {dynamic ? (
            <div tw="relative">
              <SwitchTransition mode="out-in">
                <CSSTransition<undefined>
                  key={activeIdx}
                  classNames="slide"
                  addEndListener={(node: HTMLElement, done: () => void) => {
                    node.addEventListener('transitionend', done, false);
                  }}
                >
                  <div
                    key={`${activeIdx}-partner`}
                    tw="my-6 grid grid-cols-3 lg:flex"
                    css={css`
                      ${!noMargin ? tw`justify-center` : tw`justify-between`}
                      @media (max-width: 1024px) {
                        a {
                          ${tw`mb-3`}
                          ${tw`flex`}
                        ${tw`justify-center`}
                        }
                      }
                    `}
                  >
                    {activeItem?.map((p, i) => {
                      return (
                        <a
                          key={`${i}-partner`}
                          href={p?.description || ''}
                          target="__blank"
                          aria-label={`ke halaman ${p?.title}`}
                        >
                          <Img
                            alt={p?.title}
                            tw="mr-1 lg:mr-4"
                            fluid={p?.fluid as FluidObject}
                            css={css`
                              height: ${getSizeFromUrl(p?.fluid?.srcSet).h};
                              width: ${getSizeFromUrl(p?.fluid?.srcSet).w};
                              @media (max-width: 1024px) {
                                height: ${getSizeFromUrl(p?.fluid?.srcSet, true).h};
                                width: ${getSizeFromUrl(p?.fluid?.srcSet, true).w};
                              }
                            `}
                          />
                        </a>
                      );
                    })}
                  </div>
                </CSSTransition>
              </SwitchTransition>
              <div tw="w-full mt-8 absolute bottom[-1rem] lg:bottom[-1.5rem] left-0">
                <div tw="flex justify-center">
                  {[...Array(totalData)].map((_, i) => (
                    <Ellipse
                      key={i}
                      width="10px"
                      height="10px"
                      fill={i === (currentIndex || 0) ? '#2B50CB' : '#DADADA'}
                      tw="cursor-pointer mx-1"
                      onClick={() => {
                        setCurrentIndex(i);
                        clearTimeout(timeout);
                      }}
                    ></Ellipse>
                  ))}
                </div>
              </div>
            </div>
          ) : (
            <div
              tw="my-6 grid grid-cols-3 lg:flex"
              css={css`
                ${!noMargin ? tw`justify-center` : tw`justify-between`}
                @media (max-width: 1024px) {
                  a {
                    ${tw`mb-3`}
                    ${tw`flex`}
                  ${tw`justify-center`}
                  }
                }
              `}
            >
              <a
                href="https://www.unitedindiversity.org/"
                target="__blank"
                aria-label="ke halaman united in diversity"
              >
                <h3 tw="hidden">United in Diversity</h3>
                <Img
                  alt="United in Diversity"
                  tw="mr-1 lg:mr-4"
                  fluid={uid as FluidObject}
                  css={css`
                    ${!noMargin
                      ? css`
                          height: 50px;
                          width: 150px;
                        `
                      : css`
                          height: 34px;
                          width: 112px;
                        `}
                    @media (max-width: 1024px) {
                      ${!noMargin
                        ? css`
                            height: 34px;
                            width: 112px;
                          `
                        : css`
                            height: 27px;
                            width: 89px;
                          `}
                    }
                  `}
                />
              </a>
              <a
                href="https://www.bintan-resorts.com/id/resorts/mutiara-beach-resort/"
                target="__blank"
                aria-label="ke halaman mutiara beach resort"
              >
                <h3 tw="hidden">Mutiara Beach Resort</h3>
                <Img
                  alt="Mutiara Beach Resort"
                  tw="mr-1 lg:mr-4"
                  fluid={mutiara as FluidObject}
                  css={css`
                    ${!noMargin
                      ? css`
                          height: 50px;
                          width: 54px;
                        `
                      : css`
                          height: 40px;
                          width: 41px;
                        `}
                    @media (max-width: 1024px) {
                      ${!noMargin
                        ? css`
                            height: 40px;
                            width: 41px;
                          `
                        : css`
                            height: 31px;
                            width: 31px;
                          `}
                    }
                  `}
                />
              </a>
              <a
                href="https://www.tgi.co.id/"
                target="__blank"
                aria-label="ke halaman transportasi gas indonesia"
              >
                <h3 tw="hidden">Transportasi Gas Indonesia</h3>
                <Img
                  alt="Transportasi Gas Indonesia"
                  tw="mr-1 lg:mr-4"
                  fluid={transgasindo as FluidObject}
                  css={css`
                    ${!noMargin
                      ? css`
                          height: 50px;
                          width: 40px;
                        `
                      : css`
                          height: 40px;
                          width: 30px;
                        `}
                    @media (max-width: 1024px) {
                      ${!noMargin
                        ? css`
                            height: 40px;
                            width: 30px;
                          `
                        : css`
                            height: 31px;
                            width: 23px;
                          `}
                    }
                  `}
                />
              </a>
              <a href="https://www.narasi.tv/" target="__blank" aria-label="ke halaman narasi tv">
                <h3 tw="hidden">Narasi TV</h3>
                <Img
                  alt="Narasi TV"
                  tw="mr-1 lg:mr-4"
                  fluid={narasi as FluidObject}
                  css={css`
                    ${!noMargin
                      ? css`
                          height: 46px;
                          width: 147px;
                        `
                      : css`
                          height: 29px;
                          width: 93px;
                        `}
                    @media (max-width: 1024px) {
                      ${!noMargin
                        ? css`
                            height: 29px;
                            width: 93px;
                          `
                        : css`
                            height: 27px;
                            width: 86px;
                          `}
                    }
                  `}
                />
              </a>
              <a href="https://danadidik.id/" target="__blank" aria-label="ke halaman dana didik">
                <h3 tw="hidden">Dana Didik</h3>
                <Img
                  alt="Dana Didik"
                  tw="mr-1 lg:mr-4"
                  fluid={danaDidik as FluidObject}
                  css={css`
                    ${!noMargin
                      ? css`
                          height: 46px;
                          width: 89px;
                        `
                      : css`
                          height: 44px;
                          width: 77px;
                        `}
                    @media (max-width: 1024px) {
                      ${!noMargin
                        ? css`
                            height: 44px;
                            width: 77px;
                          `
                        : css`
                            height: 40px;
                            width: 72px;
                          `}
                    }
                  `}
                />
              </a>
              <a
                href="https://www.akseleran.co.id/"
                target="__blank"
                aria-label="ke halaman akseleran"
              >
                <h3 tw="hidden">Akseleran</h3>
                <Img
                  alt="Akseleran"
                  tw="mr-1 lg:mr-4"
                  fluid={akseleran as FluidObject}
                  css={css`
                    ${!noMargin
                      ? css`
                          height: 46px;
                          width: 185px;
                        `
                      : css`
                          height: 29px;
                          width: 116px;
                        `}
                    @media (max-width: 1024px) {
                      ${!noMargin
                        ? css`
                            height: 29px;
                            width: 116px;
                          `
                        : css`
                            height: 27px;
                            width: 108px;
                          `}
                    }
                  `}
                />
              </a>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export const partnerQuery = graphql`
  query getPartnerData {
    uid: file(relativePath: { eq: "images/partner/uid.png" }) {
      childrenImageSharp {
        fluid(maxHeight: 54) {
          ...FileImageSharpFluid
        }
      }
    }
    mutiara: file(relativePath: { eq: "images/partner/mutiara.png" }) {
      childrenImageSharp {
        fluid(maxHeight: 54) {
          ...FileImageSharpFluid
        }
      }
    }
    transgasindo: file(relativePath: { eq: "images/partner/transgasindo.png" }) {
      childrenImageSharp {
        fluid(maxHeight: 54) {
          ...FileImageSharpFluid
        }
      }
    }
    narasi: file(relativePath: { eq: "images/partner/narasi.png" }) {
      childrenImageSharp {
        fluid(maxHeight: 46) {
          ...FileImageSharpFluid
        }
      }
    }
    danaDidik: file(relativePath: { eq: "images/partner/dana-didik.png" }) {
      childrenImageSharp {
        fluid(maxHeight: 46) {
          ...FileImageSharpFluid
        }
      }
    }
    akseleran: file(relativePath: { eq: "images/partner/akseleran.png" }) {
      childrenImageSharp {
        fluid(maxHeight: 46) {
          ...FileImageSharpFluid
        }
      }
    }
  }
`;

interface PartnerProps {
  title?: any;
  noMargin?: boolean;
  partners?: any;
  dynamic?: boolean;
}

const Partner: React.FC<PartnerProps> = ({ title, noMargin, partners, dynamic }) => {
  return (
    <StaticQuery
      query={partnerQuery}
      render={data => (
        <PartnerComp
          data={data}
          title={title}
          noMargin={noMargin}
          partners={partners}
          dynamic={dynamic}
        />
      )}
    />
  );
};

export default Partner;
